<template>
  <v-card min-width="300px" height="250px" flat class="rounded-lg">
    <v-card-title class="text-color-title caption font-weight-bold py-1">
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn icon @click="menuClick()">
        <v-icon>mdi-menu-open</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text class="pb-0">
      <v-textarea
        v-model="text"
        filled
        dense
        no-resize
        rows="4"
        :rules="MIXINS_REQUIRED_RULES"
        class="rounded-lg"
      ></v-textarea>
    </v-card-text>
    <v-card-actions class="px-4 pt-0">
      <v-spacer></v-spacer>
      <v-btn small class="bg-secondary text-color-white">Send</v-btn>
      <v-btn small icon>
        <v-icon>mdi-fullscreen</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    title: "ANNOUNCEMENTS",
    text: ""
  }),
  methods: {
    // getData() {
    //   this.citation = {
    //     verse: "John 3:16",
    //     text:
    //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sodales interdum odio non mattis. Etiam placerat elit luctus arcu feugiat tempus. Nulla facilisi. Duis luctus neque vel pretium pretium. Sed."
    //   };
    // },
    menuClick() {
      // alert("click");
    }
  },
  mounted() {
    // this.getData();
  }
};
</script>
